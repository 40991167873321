<template>
    <div class="Coupon">
          <div class="item-header _flex">

            <div class="screen-list-item">
                <!-- <a-tree v-model="categoryCheckedKeys" checkable :tree-data="category_list"/> -->
                <a-tree-select
                    style="width: 300px;margin-right: 10px;"
                    v-model="serachParams.pid"
                    :tree-data="category_list"
                    tree-checkable
                    :maxTagCount="1"
                    :show-checked-strategy="SHOW_PARENT"
                    @change="categoryCheckedChange"
                    placeholder="请选择目录"
                />
            </div>

            <div class="screen-list-item">
                <span class="screen-item-label">状态:</span>
                <a-select :value="serachParams.state" style="width: 100px" @change="_changeState">
                  <a-select-option value="all">全部</a-select-option>
                  <a-select-option value="0">未同步</a-select-option>
                  <a-select-option value="1">同步中</a-select-option>
                  <a-select-option value="2">已同步</a-select-option>
                </a-select>
            </div>
            
            <div class="screen-list-item">
                <!-- <a-button style="margin-right:20px" @click="resetBtn()">重置</a-button> -->
                <a-button type="primary" @click="serachBtn()">查询</a-button>
            </div>
        </div>

        
        <div class="coupon-content" :style="{'min-height':minHeight}">
            <div style="margin-top:15px;">
                <a-table :loading="tableLoadState" :columns="columns" :dataSource="infoData" :pagination="false" bordered>
                    <div slot="action" slot-scope="text, record, index">
                        <!-- <div class="edit-item">
                            <span @click="_sync(record)">同步</span>
                        </div> -->
                        <div class="edit-item">
                            <span @click="_open_file_list(record)">文件列表</span>
                        </div>
                    </div>
                </a-table> 
            </div>
            
            <div style="margin: 20px 0;text-align: right;" v-if="total > 0">
                <a-pagination :defaultCurrent="1" :total="total" @change="pageChange" />
            </div>
        </div>


        <a-drawer :title="path + '文件列表'" placement="right" :closable="false" width="800" @close="_hideCancel" :visible="fileVisible">
            <a-table :columns="file_columns" :dataSource="file_list" :pagination="false" bordered>
                    <div slot="action" slot-scope="text, record, index">
                        <div class="edit-item">
                            <span v-if="record.state == '--'" @click="_sync(record)">同步</span>
                            <span v-else @click="_sync(record)">重新同步</span>
                        </div>
                    </div>
                </a-table> 
        </a-drawer>

    </div>
</template>
<script>
import moment from 'moment';
import axios from 'axios';
export default {
    name:'Source',
    components: { },
    data(){
        return{

            columns: [
                { title: '序号', dataIndex: 'number', key: 'number',align:'center',width:20 },
                { title: '目录路径', dataIndex: 'path', key: 'path',align:'center',width:100 },
                // { title: '最后同步时间', dataIndex: 'last_time', key: 'last_time',align:'center',width:120, },
                // { title: '状态', dataIndex: 'state', key: 'state',align:'center',width:120, },
                { title: '操作', dataIndex: 'x', key: 'x',align:'center',width: 100 ,scopedSlots: { customRender: 'action' }},
            ],

            file_columns: [
                { title: '序号', dataIndex: 'number', key: 'number',align:'center',width:50 },
                { title: '文件名称', dataIndex: 'file_name', key: 'file_name',align:'center',width:150 },
                { title: '最近同步时间', dataIndex: 'last_time', key: 'last_time',align:'center',width:120, },
                { title: '状态', dataIndex: 'state', key: 'state',align:'center',width:120, },
                { title: '操作', dataIndex: 'x', key: 'x',align:'center',width: 120 ,scopedSlots: { customRender: 'action' }},
            ],

            infoData:[],
            startDate: '',
            endDate: '',
            code_name:'',
            minHeight:'500px',
            total:0,
            page:1,

            serachParams:{
                pid: null,
                pageNum: 1,
                pageSize: 10,
                state:"all",
            },

            category_list:[],
            categoryCheckedKeys:[],
            tableLoadState:false,
            SHOW_PARENT:'SHOW_ALL',
            fileVisible:false,
            fileTitle:'文件列表',
            file_list:[],
            is_series: 0, //是否是专项课
            path: '',//目录路径
        }
    },
    created(){
        this._get_category();
        this._getData();
    },
    mounted(){
         
    },
    methods:{
     moment,
     serachBtn(){
        this.serachParams.pageNum = 1;
        this._getData();
     },
     onTtableClick(){
        this.addVisible = true;
        this._get_vip_manage();
     },
     resetBtn(){
        this.serachParams = {
            pid: null,
            pageNum: 1,
            pageSize: 10,
            state:"all",
        }
        this._getData();
     },
     pageChange(pageNumber){
        this.serachParams.pageNum = pageNumber;
        this._getData();
     },
     _getData(){
        let urlCode;
        this.tableLoadState = true;
        this.$axios.post(8000202, this.serachParams,res => {
            if(res.data.code==1){
                console.log(res.data);
                this.infoData = res.data.data.list;
                this.total = res.data.data.count;
            }else{
                this.infoData = [];
                this.total = 0;
            }
            this.tableLoadState = false;
            // this.$message.destroy()
        });
     },
     _get_category(){
        this.$axios.get(8000203, {},res => {
            this.category_list = res.data.data;
        });
     },
     _changeState(value){
        console.log(value);
     },
     categoryCheckedChange(value){
        console.log(value);
     },
     _sync(item){
        this.$message.loading('数据加载中...');
        item.is_series = this.is_series;
        item.path = this.path;
        axios({
            method:'post',
            url:'https://smart.sikegroup.com/onlineclass/v1/source/add_sync',
            data: item
        }).then((response)=>{
            if (response.data.code == 1) {
                // this.$message.success(response.data.message);
                this._hideCancel();
                this._getData();
            } else {
                this.$message.error(response.data.message);
            }
            this.$message.destroy()
        })
     },
     _open_file_list(item){
        console.log(item);
        this.is_series = item.is_series;
        this.path = item.path;
        this.$axios.get(8000205, {pid: item.Id},res => {
            if (res.data.code == 1) {
                this.fileVisible = true;
                this.file_list = res.data.data;
            } else {
                this.file_list = [];
            }
        });
     },
     _hideCancel(){
        this.fileVisible = false;
        this.is_series = 0;
        this.path = '';
     },
    },
}
</script>

<style lang="less" > 
    .Coupon{
         .item-header{
            padding: 15px 20px;
            background: #fff;
            border-radius: 4px;
            .screen-list-item{
                margin-right: 20px;
            }
            .screen-item-label{
                margin-right: 10px;
                color: #666;
                font-size: 14px;
                white-space: nowrap;
            }
        }
        .coupon-content{
            padding: 15px;
            background: #fff;
            margin-top: 20px;
            border-radius: 4px;
            .content-title{
                span{
                    font-size: 18px;
                    color: #333;
                }
                i{
                    margin-left: 10px;
                    font-size: 18px;
                    cursor: pointer;
                }
            }

            .smalldash{
                line-height: 30px;
            }
            .edit-item{
                font-size:12px;
            }
        }
    }
    .Coupon .ant-select-selection--single,.Coupon .ant-input{height: 36px;}
    .Coupon .ant-select-selection__rendered{line-height: 34px;}
    .Coupon .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
        padding: 16px 6px;
    }
    .coupon_form{padding-bottom: 30px;}
    .coupon_form .ant-row .ant-form-item-label{text-align: left;}
    .coupon_form .ant-radio-group .ant-input-number{width: 124px;}
    .coupon_form .ant-input-number{width:330px;}

    .item-list{
        display: flex;
        overflow: hidden;
        margin-bottom: 25px;
        .item-content{
            float: right;
            width: 400px;
        }
        label{
            margin-top: 6px;
            margin-right: 15px;
        }
    }

    .edit-item > span {
        color: #2473d5;
        cursor: pointer;
    }

    ._flex {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        /* justify-content: space-between; */
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
</style>